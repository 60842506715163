import React, { useState } from "react";
import { Button, Select, TextField, SelectItem, Label, CircularProgress } from "@firecms/ui";
import { DataSource, Entity } from "@firecms/core";
import { Conversation } from "../collections/conversations";
import { FetchCollectionProps } from "@firecms/core/dist/types/datasource";

interface ExportConversationsSheetProps {
  path: string;
  dataSource: DataSource;
}

const DEFAULT_MAX_ITEMS = 100;
const MAX_ITEMS_TO_EXPORT = 10000;

export function ExportConversationsSheet({ path, dataSource }: ExportConversationsSheetProps) {
  const [leadFilter, setLeadFilter] = useState("all");
  const [maxItems, setMaxItems] = useState(`${100}`);
  const [loading, setLoading] = useState(false);
  const [maxItemsError, setMaxItemsError] = useState<string | undefined>();

  const createTranscript = (messages: { sender:string, text: string }[]) => {
    return messages
      .map(m => `${m.sender}: ${m.text}`)
      .join("\n");
  };

  const handleMaxItemsChange = (value: string) => {
    const numValue = parseInt(value);
    if (isNaN(numValue)) {
      setMaxItemsError("Please enter a valid number");
    } else if (numValue > MAX_ITEMS_TO_EXPORT) {
      setMaxItemsError("Maximum allowed is 10,000");
    } else if (numValue < 1) {
      setMaxItemsError("Minimum allowed is 1");
    } else {
      setMaxItemsError(undefined);
    }
    setMaxItems(value);
  };

  const fetchValidConversations = async (targetLimit: number): Promise<Entity<Conversation>[]> => {
    let validConversations: Entity<Conversation>[] = [];
    let lastEntity: Entity<Conversation> | null = null;
    const batchSize = 500;

    while (validConversations.length < targetLimit) {
      const queryParams:FetchCollectionProps<Conversation> = {
        path,
        limit: batchSize,
        orderBy: "createdAt",
        order: "desc"
      }
      if(!!lastEntity) {
        queryParams.filter = {"createdAt": ["<", lastEntity.values.createdAt]};
      }

      const conversations = await dataSource.fetchCollection<Conversation>(queryParams);

      // The previous was the last page
      if (conversations.length === 0) {
        console.log(`This is the last page, no more conversations`);
        return validConversations.slice(0, targetLimit);
      }
      else {
        lastEntity = conversations[conversations.length - 1];
      }

      const validBatch = conversations.filter(conv => {
        if(conv.values.messages.length < 2) return false;
        if(leadFilter === "leads") {
          const dataPayload = conv.values.data;
          return !!dataPayload["name"] && (!!dataPayload["email"] || !!dataPayload["phone"]);
        }
        return true;
      });
      validConversations = [...validConversations, ...validBatch];
      // This is the last page
      if (conversations.length < batchSize) {
        console.log(`This is the last page, the number of retrieved is less than batch size`);
        return validConversations.slice(0, targetLimit);
      }
    }
    return validConversations.slice(0, targetLimit);
  };

  const prepareCSVData = (conversations: Entity<Conversation>[]) => {
    let dataColumns = new Set<string>();
    conversations.forEach(conv => {
      if (conv.values.data) {
        Object.keys(conv.values.data).forEach(key => dataColumns.add(key));
      }
    });

    const headers = [
      "id",
      "createdAt",
      "chatOpenedAt",
      "deviceType",
      "userAgent",
      "referral",
      "transcript",
      ...Array.from(dataColumns).map(key => `data.${key}`)
    ];

    const rows = conversations.map(conv => {
      const row: Record<string, string> = {
        id: conv.id,
        createdAt: conv.values.createdAt?.toISOString(),
        chatOpenedAt: conv.values.chatOpenedAt?.toISOString() || "",
        deviceType: conv.values.clientMetadata?.deviceType || "unknown",
        userAgent: conv.values.clientMetadata?.userAgent || "unknown",
        referral: conv.values.clientMetadata?.referral || "unknown",
        transcript: createTranscript(conv.values.messages || [])
      };
      for(const key of dataColumns) {
        row[`data.${key}`] = conv.values.data?.[key] || "";
      }
      return row;
    });

    return { headers, rows };
  };

  const generateCSV = (headers: string[], rows: Record<string, string>[]) => {
    return [
      headers.join(","),
      ...rows.map(row => headers.map(header => {
        const value = row[header]?.toString() || "";
        return `"${value.replace(/"/g, '""')}"`;
      }).join(","))
    ].join("\n");
  };

  const downloadCSV = (csvContent: string) => {
    const link = document.createElement("a");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);

    const fileName = `${path}_export_${new Date().getTime()}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleExport = async () => {
    try {
      setLoading(true);
      const targetLimit = parseInt(maxItems) || DEFAULT_MAX_ITEMS;
      const validConversations = await fetchValidConversations(targetLimit);
      const { headers, rows } = prepareCSVData(validConversations);
      const csvContent = generateCSV(headers, rows);
      downloadCSV(csvContent);

    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 h-full w-96 min-w-[480px]">
      <div className="space-y-6">
        <div>
          <h2 className="text-xl font-semibold mb-4">Export Conversations</h2>
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
            Select your export preferences below.
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400 mb-6">
            This will export only conversations with an interaction from a client.
          </p>
        </div>

        <div className="space-y-4">
          <div>
            <Label htmlFor="leadFilter" className="mb-2 block">
              Filter Type
            </Label>
            <Select
              id="leadFilter"
              value={leadFilter}
              onChange={(event) => setLeadFilter(event.target.value)}
              className="w-full"
              disabled={loading}
            >
              <SelectItem value="all">All Conversations</SelectItem>
              <SelectItem value="leads">Conversations with Leads Only</SelectItem>
            </Select>
          </div>

          <div>
            <Label htmlFor="maxItems" className="mb-2 block">
              Maximum Number of Conversations
            </Label>
            <TextField
              id="maxItems"
              type="number"
              placeholder="Enter max items"
              value={maxItems}
              onChange={(event) => handleMaxItemsChange(event.target.value)}
              className="w-full"
              disabled={loading}
              min={1}
              max={10000}
              error={!!maxItemsError}
              label={maxItemsError}
              />
          </div>

        </div>

        <div className="pt-4">
          <Button
            color="primary"
            onClick={handleExport}
            className="w-full relative"
            disabled={loading || !!maxItemsError}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <CircularProgress size={"small"}/>
                <span className="ml-2">Exporting...</span>
              </div>
            ) : (
              "Export Conversations"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
