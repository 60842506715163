import { ConversationEventType } from "./triggers";
import { DocumentReference } from "firebase/firestore";
import { buildCollection, buildProperty } from "@firecms/core";

export type ConversationEvent = {
  type: ConversationEventType;
  conversation: DocumentReference;
  createdAt: Date;
  context: {
    client: {
      ip: string,
      userAgent: string,
      deviceType: string,
      referral: string,
      language: string,
      country: string,
      city: string,
      region: string,
      latitude: number,
      longitude: number,
    },
    info: {
      conversationCompleted: boolean,
      createdAt: Date
    },
    data: object,
  }
  processed: boolean,
  changes?: string[];
  processedAt: Date,
  triggers: Array<{
    trigger: DocumentReference,
    endpoint: string,
    body: string,
    responseCode: number,
    responseBody: string
  }>
};

export const eventsCollection = buildCollection<Partial<ConversationEvent>>({
  name: "Events",
  singularName: "Event",
  path: "events",
  id: "events",
  icon: "bolt",
  initialSort: ["createdAt", "desc"],
  editable: false,
  openEntityMode: "full_screen",
  hideIdFromForm: true,
  hideIdFromCollection: true,
  group: "Main",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: true,
    create: false,
    delete: false
  }),
  properties: {
    type: buildProperty({
      name: "Type",
      readOnly: true,
      validation: { required: true },
      dataType: "string",
      enumValues: {
        started: "Started",
        updated: "Updated",
        ended: "Ended"
      }
    }),
    conversation: buildProperty(({ values, path }) => {
      const chatbotId = path.split("/").slice(1, 2).join("");
      return {
        name: "Conversation",
        readOnly: true,
        validation: { required: false },
        dataType: "reference",
        previewProperties: ["__name__", "createdAt"],
        path: `chatbots/${chatbotId}/conversations`
      }
    }),
    createdAt: buildProperty({
      readOnly: true,
      name: "Created At",
      validation: { required: true },
      dataType: "date"
    }),
    context: buildProperty({
      readOnly: true,
      name: "Context",
      dataType: "map"
    }),
    processed: buildProperty({
      readOnly: true,
      name: "Event processed",
      dataType: "boolean"
    }),
    processedAt: buildProperty({
      readOnly: true,
      name: "Event processed at",
      dataType: "date"
    }),
    triggers: buildProperty({
      readOnly: true,
      name: "Triggers invoked by this event",
      dataType: "array",
      of: {
        dataType: "map"
      }
    })
  }
});
