import React from "react";
import {EntityCustomView, PropertyFieldBinding, useAuthController} from "@firecms/core";
import {Container} from "@firecms/ui";
import {llmConfigProperty} from "../collections/chatbot/chatbot_llm_config_properties";
import {MemberRole} from "../collections/team";

export const chatbotLLMConfigView: EntityCustomView = {
  key: "llm_config",
  name: "LLM Config",
  includeActions: true,
  Builder: ({
              collection,
              entity,
              modifiedValues,
              formContext
            }) => {
    const authController = useAuthController();
    const hasPermission = [MemberRole.admin].includes(authController.extra?.role);
    return (
      <Container className={"my-16 flex flex-col gap-4 w-full"}>
        <PropertyFieldBinding context={formContext}
                              property={llmConfigProperty}
                              disabled={!hasPermission}
                              propertyKey={"llmConfig"}/>
      </Container>
    )
  }
};
