import { buildProperty } from "@firecms/core";
import { ChatbotLLMConfig, LLMConfig, LLMModel, LLMProvider } from "./chatbots";


export const llmConfigProperty = buildProperty<ChatbotLLMConfig>({
  name: "LLM Config",
  dataType: "map",
  properties: {
    conversation: buildProperty<LLMConfig>({
      name: "Conversation",
      dataType: "map",
      properties: {
        provider: buildProperty({
          name: "Provider",
          validation: {required: true},
          dataType: "string",
          enumValues: {
            openai: "OpenAI",
            google: "Google"
          },
          defaultValue: "openai"
        }),
        model: buildProperty({
          name: "Model",
          validation: {required: true},
          dataType: "string",
          enumValues: {
            "gemini-2.0-flash": "Gemini 2.0 Flash",
            "gemini-2.0-pro-exp-02-05": "Gemini 2.0 Pro Exp",
            "gpt-4-turbo": "GPT-4 Turbo",
            "gpt-4o": "GPT-4O",
            "o3-mini": "O3 Mini"
          },
          defaultValue: "gpt-4-turbo"
        })
      }
    }),
    data: buildProperty<LLMConfig>({
      name: "Data",
      dataType: "map",
      properties: {
        provider: buildProperty({
          name: "Provider",
          validation: {required: true},
          dataType: "string",
          enumValues: {
            openai: "OpenAI",
            google: "Google"
          },
          defaultValue: "openai"
        }),
        model: buildProperty({
          name: "Model",
          validation: {
            required: true,
          },
          dataType: "string",
          enumValues: {
            "gemini-2.0-flash": "Gemini 2.0 Flash",
            "gemini-2.0-pro-exp-02-05": "Gemini 2.0 Pro Exp",
            "gpt-4-turbo": "GPT-4 Turbo",
            "gpt-4o": "GPT-4O",
            "o3-mini": "O3 Mini"
          },
          defaultValue: "gpt-4-turbo"
        })
      }
    })
  },
  defaultValue: {
    conversation: {
      provider: "openai" as LLMProvider.OPENAI,
      model: "gpt-4-turbo" as LLMModel
    },
    data: {
      provider: "openai" as LLMProvider.OPENAI,
      model: "gpt-4-turbo" as LLMModel
    }
  }
})
