import React, {useState} from "react";
import { CollectionActionsProps } from "@firecms/core";
import { Button, Sheet } from "@firecms/ui";
import { ExportConversationsSheet } from "../components/ExportConversationSheet";

export function ExportConversationsAction({ path, context }: CollectionActionsProps) {

  const [open, setOpen] = useState(false);

  const onClick = async (event: React.MouseEvent) => {
    setOpen(true);
  };

  return (
    <div>
    <Button onClick={onClick}
            color="primary"
            variant={"text"}>
      Export Conversations
    </Button>
      <Sheet open={open} onOpenChange={setOpen}>
        <ExportConversationsSheet
          path={path}
          dataSource={context.dataSource}
        />
      </Sheet>
    </div>
  );

}
