import React from "react";
import { ColorResult, TwitterPicker } from "react-color";
import { FieldHelperText, FieldProps, useModeController } from "@firecms/core";
import { Label } from "@firecms/ui";

export default function HexColorTextField({
                                            property,
                                            value,
                                            setValue,
                                            setFieldValue,
                                            customProps,
                                            touched,
                                            includeDescription,
                                            showError,
                                            error,
                                            isSubmitting,
                                            context, // the rest of the entity values here
                                            ...props
                                          }: FieldProps) {

  const { mode } = useModeController();
  const backgroundColor = customProps?.color ?? (mode === "light" ? "#ffffff" : "#18181c");
  const primaryColor = customProps?.color ?? (mode === "light" ? "#111111" : "#ffffff");
  const colorBorder = customProps?.color ?? (mode === "light" ? "#CFCFD6" : "#35353a");
  const defaultColors = ["#FF6B6B", "#4ECDC4", "#FFE66D", "#FF9F1C", "#2EC4B6", "#011627", "#E71D36", "#D90368"];
  const inputStyles = {
    default: {
      input: {
        backgroundColor,
        color: primaryColor,
        height: "30px",
        borderColor: colorBorder
      },
      label: {
        backgroundColor,
        color: primaryColor
      },
      card: {
        boxShadow: "none",
        borderColor: colorBorder,
        borderBottomRightRadius: "0.375rem",
        borderBottomLeftRadius: "0.375rem",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        borderWidth: "1px",
        backgroundColor
      }
    }
  };
  const colorPreviewStyles = {
    width: "100%",
    height: "60px",
    backgroundColor: value ?? { hex: "#000000" },
    borderTopRightRadius: "0.375rem",
    borderTopLeftRadius: "0.375rem",
    borderWidth: "1px",
    borderBottomWidth: "0",
    borderColor: colorBorder,
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "0"
  }
  return (
    <>
      <Label className={"h-8text-text-secondary dark:text-text-secondary-dark ml-3.5"} title={property.name}>{property.name}</Label>
      <div style={colorPreviewStyles}></div>

      <TwitterPicker
        triangle={"hide"}
        width={"100%"}
        colors={defaultColors}
        color={value ?? { hex: "#000000" }}
        styles={inputStyles}
        onChangeComplete={(color: ColorResult) => {
          setValue(
            color.hex
          );
        }}
      />
      <FieldHelperText includeDescription={includeDescription}
                       showError={showError}
                       error={error}
                       property={property}/>
    </>

  );

}
